import { SEO } from 'components/blocks/seo';
import Mdx from 'components/containers/mdx';
/* pages */
import Hero from 'components/pages/white-paper/hero';
import styles from 'components/pages/white-paper/white-paper.module.scss';
import 'components/pages/white-paper/white-paper.scss';
/* shared */
import CaseStudies from 'components/shared/case-studies';
import { Trait } from 'components/shared/trait';
/* deps */
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import CollaborativeLoadTestingIllustration from 'svg/white-paper/collaborativeloadtesting.inline.svg';
/* utils */
import SEO_DATA from 'utils/seo-metadata';

const componentsForNativeReplacement = {
  Trait,
};

const ModernLoadTesting = (props) => {
  const {
    data: { file },
  } = props;
  const { body: content, headings } = file.children[0];

  return (
    <DefaultLayout>
      <Hero title="Modern Load Testing for Engineering Teams" illustration={CollaborativeLoadTestingIllustration} />

      <Mdx
        mode="withToc"
        content={content}
        componentsForNativeReplacement={componentsForNativeReplacement}
        className={styles.htmlContent}
        headings={headings}
      />

      <CaseStudies title="Related Resources" page="whitepaper" cases={['whitePaper', 'carvana2', 'manifesto']} />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    file(name: { eq: "modern-load-testing-for-engineering-teams" }) {
      children {
        ... on Mdx {
          body
          headings {
            depth
            value
          }
        }
      }
    }
    ogImage: file(
      absolutePath: { regex: "/white-papers/modern-load-testing-for-engineering-teams-social-cover.png/" }
    ) {
      childImageSharp {
        resize(width: 1200, quality: 95) {
          src
        }
      }
    }
  }
`;

export default ModernLoadTesting;

export const Head = ({ data }) => {
  const pageMetaData = {
    data: {
      ...SEO_DATA['white-paper-collaboration'].data,
      image: data.ogImage,
    },
  };

  return <SEO {...pageMetaData} />;
};
