import { DownloadButton } from 'components/shared/download-button';
import { Heading } from 'components/shared/heading';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './hero.module.scss';

const Hero = ({ title, downloadLink, gaEventCategory, downloadFileName, illustration: Illustration }) => {
  const downloadClickHandler = () => trackCustomEvent({ label: 'main', action: 'Click', category: gaEventCategory });
  return (
    <section className={styles.wrapper}>
      <div className={`container ${styles.inner}`}>
        <div className="row">
          <div className="col-lg-5 col-12">
            <span className={styles.kicker}>White paper</span>
            <Heading className={styles.title}>{title}</Heading>
            {downloadLink && (
              <div className={styles.buttons}>
                <DownloadButton
                  title="White Paper.pdf"
                  downloadLink={downloadLink}
                  download={downloadFileName}
                  onClick={downloadClickHandler}
                />
              </div>
            )}
          </div>
          <div className="col-lg-7 col-12">
            <Illustration />
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  downloadFileName: PropTypes.string.isRequired,
  illustration: PropTypes.node.isRequired,
};

Hero.defaultProps = {};

export default Hero;
